import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName:'HomeView' */ "../views/HomeView.vue"),
  },
  {
    path: "/suggestion",
    name: "suggestion",
    meta: {
      title: "意见反馈",
    },
    component: () =>
      import(/* webpackChunkName:'suggestion' */ "../views/SuggestionView.vue"),
  },
  {
    path: "/help",
    name: "help",
    meta: {
      title: "使用帮助",
    },
    component: () =>
      import(/* webpackChunkName:'helpindex' */ "../views/HelpIndex.vue"),
  },
  {
    path: "/helpdetail",
    name: "helpdetail",
    meta: {},
    component: () =>
      import(/* webpackChunkName:'helpdetail' */ "../views/HelpDetail.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to) => {
  if (to.meta.title) {
    document.title = to?.meta?.title as string;
  }
});

export default router;
